import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { BookOpen, Clock, FileText, Calculator, Brain, ChevronRight } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { getCategoryArticles, getCategoryDetailsForArticles } from '../../services/api';
import Footer from '../../components/Footer';

const difficultyColors = {
  beginner: 'text-green-600 bg-green-50',
  intermediate: 'text-yellow-600 bg-yellow-50',
  advanced: 'text-red-600 bg-red-50'
};

export default function CategoryPage() {
  const location = useLocation();

  const { examId, categorySlug } = useParams();
  const [category, setCategory] = useState(location.state?.categoryDetails || null);
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [categoryLoaded, setCategoryLoaded] = useState(!!location.state?.categoryDetails);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Always fetch category details if loading directly
        if (!category) {
          try {
            console.log("Fetching category details for:", categorySlug);
            const categoryResponse = await getCategoryDetailsForArticles(categorySlug);
            console.log("Category response:", categoryResponse);
            
            // Make sure we have the expected data structure
            if (categoryResponse && categoryResponse.data) {
              setCategory(categoryResponse.data);
            } else {
              console.error("Invalid category response format:", categoryResponse);
            }
          } catch (error) {
            console.error("Error fetching category details:", error);
          }
        }
        
        // Fetch articles regardless of category status
        // We'll handle the display logic in the render section
        const articlesResponse = await getCategoryArticles(categorySlug);
        if (articlesResponse && articlesResponse.data) {
          setArticles(articlesResponse.data);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, [categorySlug, category]);


  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="animate-pulse">
            <div className="h-8 w-72 bg-gray-200 rounded mb-6"></div>
            <div className="h-4 w-96 bg-gray-200 rounded mb-4"></div>
            <div className="h-4 w-80 bg-gray-200 rounded mb-8"></div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {[1, 2, 3].map((i) => (
                <div key={i} className="h-48 bg-gray-200 rounded-xl"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!category) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900">Category not found</h2>
          <Link to={`${examId}`} className="text-indigo-600 hover:text-indigo-700 mt-4 inline-block">
            Go back to categories
          </Link>
        </div>
      </div>
    );
  }

  // Ensure we have the required properties with fallbacks
  const categoryName = category.name || categorySlug;
  const categoryDescription = category.description || "Learn more about this topic with our comprehensive articles.";

  return (
    <>
      <Helmet>
        <title>{`${categoryName} - ${examId.toUpperCase()} | MockTestPro`}</title>
        <meta name="description" content={categoryDescription} />
        <meta name="keywords" content={`${categorySlug}, ${examId} ${categorySlug.toLowerCase()}, ${examId} preparation`} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`https://mocktestpro.com/articles/${examId}/${categorySlug}`} />
      </Helmet>

      <div className="bg-gray-50 min-h-screen py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mb-12">
            <div className="flex items-center space-x-2 text-gray-600 mb-4">
              <Link to={`/articles/${examId}`} className="hover:text-indigo-600">
                {examId?.toUpperCase()}
              </Link>
              <ChevronRight className="h-4 w-4" />
              <span className="text-gray-900 font-medium">{category.name}</span>
            </div>
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              {category.name}
            </h1>
            <p className="text-xl text-gray-600 mb-6">
              {category.description}
            </p>
            <div className="flex items-center space-x-6 text-sm">
              <div className="flex items-center text-gray-600">
                <FileText className="h-5 w-5 mr-2" />
                <span>{articles.length || 0} Total Articles</span>
              </div>
              {/* <div className="flex items-center text-green-600">
                <BookOpen className="h-5 w-5 mr-2" />
                <span>{category.completedArticles || 0} Completed</span>
              </div> */}
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="lg:col-span-2">
              <div className="space-y-6">
                {articles.map((article) => (
                  <Link
                    key={article.id}
                    // to={`${examId}/${categoryDocId}/${article.slug}`}
                    to={`${article.slug}`}
                    className="block bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-200 group"
                  >
                    <div className="p-6">
                      <div className="flex items-center justify-between mb-3">
                        <div className="flex items-center space-x-4 text-sm text-gray-500">
                          <div className="flex items-center">
                            <Clock className="h-4 w-4 mr-1" />
                            {article.readingTime} min read
                          </div>
                          <div>{new Date(article.publishedAt).toLocaleDateString()}</div>
                          {/* <div className="flex items-center">
                            <FileText className="h-4 w-4 mr-1" />
                            {article.subArticleCount || 0} Articles
                          </div> */}
                        </div>
                        <span className={`text-sm px-3 py-1 rounded-full ${difficultyColors[article.difficulty]}`}>
                          {article.difficulty}
                        </span>
                      </div>
                      <h2 className="text-xl font-semibold text-gray-900 mb-2 group-hover:text-indigo-600 transition-colors">
                        {article.title}
                      </h2>
                      <p className="text-gray-600">{article.excerpt}</p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>

            <div className="lg:col-span-1 space-y-6">
              <div className="bg-white rounded-xl shadow-md p-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">
                  Study Resources
                </h3>
                <div className="space-y-4">
                  <div className="flex items-center space-x-3">
                    <div className="bg-indigo-100 p-2 rounded-lg">
                      <BookOpen className="h-5 w-5 text-indigo-600" />
                    </div>
                    <div>
                      <h4 className="font-medium">Study Notes</h4>
                      <p className="text-sm text-gray-600">Comprehensive materials</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-3">
                    <div className="bg-green-100 p-2 rounded-lg">
                      <FileText className="h-5 w-5 text-green-600" />
                    </div>
                    <div>
                      <h4 className="font-medium">Practice Questions</h4>
                      <p className="text-sm text-gray-600">Test your knowledge</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-3">
                    <div className="bg-purple-100 p-2 rounded-lg">
                      <Calculator className="h-5 w-5 text-purple-600" />
                    </div>
                    <div>
                      <h4 className="font-medium">Formula Sheet</h4>
                      <p className="text-sm text-gray-600">Quick reference guide</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
