export const categories = [
  {
    name: 'Arithmetic',
    topics: [
      { name: 'Number System', url: '/exam/number-system-questions' },
      { name: 'Simplification', url: '/exam/simplification-questions' },
      { name: 'HCF and LCM', url: '/exam/hcf-and-lcm-questions' },
      { name: 'Average', url: '/exam/average-and-percentage-problems' },
      { name: 'Percentage', url: '/exam/average-and-percentage-problems' }, // Same examId as Average
      { name: 'Profit and Loss', url: '/exam/profit-and-loss-questions' },
      { name: 'Discount', url: '/exam/discount-questions' },
      { name: 'Simple Interest', url: '/exam/simple-interest-and-compound-interest-questions' },
      { name: 'Compound Interest', url: '/exam/simple-interest-and-compound-interest-questions' }, // Same examId as Simple Interest
      { name: 'Ratio and Proportion', url: '/exam/ratio-and-proportion-questions' },
      { name: 'Partnership', url: '/exam/partnership-questions' },
      { name: 'Mixture and Alligation', url: '/exam/mixture-and-alligation-questions' },
      { name: 'Time and Work', url: '/exam/time-and-work-questions' },
      { name: 'Pipe and Cistern', url: '/exam/pipe-and-cistern-questions' },
      { name: 'Speed, Time, and Distance', url: '/exam/time-speed-and-distance-questions' },
      { name: 'Boat and Stream', url: '/exam/boat-and-stream-questions' },

    ],
  },
  {
    name: 'Algebra',
    topics: [
      { name: 'Basics of Algebra', url: '/exam/algebra-questions' },
      { name: 'Linear Equations', url: '/exam/linear-equations-questions' },
      { name: 'Quadratic Equations', url: '/exam/quadratic-equations-questions' }    
    ],
  },
  {
    name: 'Geometry',
    topics: [
      { name: 'Basics of Geometry (Lines, Angles)', url: '/exam/geometry-questions' },
  { name: 'Triangles (Properties, Similarity, Congruence)', url: '/exam/triangles-questions' },
  { name: 'Circles (Properties and Theorems)', url: '/exam/circles-questions' },
  { name: 'Polygons', url: '/exam/polygons-questions' },
  { name: 'Coordinate Geometry', url: '/exam/coordinate-geometry-questions' },

    ],
  },
  {
    name: 'Mensuration',
    topics: [
      { name: 'Area and Perimeter of 2D Figures', url: '/exam/area-and-perimeter-questions' },
      { name: 'Surface Area and Volume of 3D Figures', url: '/exam/surface-area-and-volume-questions' },
    ],
  },
  {
    name: 'Trigonometry',
    topics: [
      { name: 'Trigonometric Ratios', url: '/exam/trigonometric-ratios-questions' },
      { name: 'Trigonometric Identities', url: '/exam/trigonometric-identities-questions' },
      { name: 'Heights and Distances', url: '/exam/heights-and-distances-questions' },
    ],
  },
  {
    name: 'Data Interpretation',
    topics: [
      // { name: 'Tabulation', url: '/data-interpretation/tabulation' },
      // { name: 'Bar Graph', url: '/data-interpretation/bar-graph' },
      // { name: 'Pie Chart', url: '/data-interpretation/pie-chart' },
      // { name: 'Line Graph', url: '/data-interpretation/line-graph' },
    ],
  },
  {
    name: 'Miscellaneous',
    topics: [
      { name: 'Sets and Venn Diagrams', url: '/exam/sets-and-venn-diagrams-questions' },
  { name: 'Sequence and Series', url: '/exam/sequences-and-series-questions' },
  { name: 'Probability', url: '/exam/probability-questions' },
  { name: 'Permutations and Combinations', url: '/exam/permutations-and-combinations-questions' },
  { name: 'Problems on Ages', url: '/exam/problems-on-ages-questions' },

    ],
  },
];