import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { Search, Book, Calculator, Shapes, Ruler, Triangle, PieChart, Brain, ChevronRight, Timer, Award, TrendingUp, Lightbulb, Target, Sparkles, Infinity, Percent, DollarSign, Clock, Droplets, Car, Bot as Boat, FunctionSquare as Function, Equal, Box, Circle, Hexagon, Table, BarChart2, PieChart as PieChart2, LineChart, AirVent as Venn, ListOrdered, Dice1 as Dice, Users, Hash } from 'lucide-react';
import { categories } from './data';
import Footer from '../../components/Footer';
export function AptitudeHomePage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);

  const filteredCategories = categories.map(category => ({
    ...category,
    topics: category.topics.filter(topic =>
      topic.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  })).filter(category => 
    selectedCategory ? category.name === selectedCategory : true
  );

  const getCategoryColor = (name) => {
    switch (name) {
      case 'Arithmetic':
        return { 
          bg: 'bg-blue-50', 
          icon: 'text-blue-500', 
          hover: 'hover:bg-blue-100',
          button: 'bg-blue-500 hover:bg-blue-600'
        };
      case 'Algebra':
        return { 
          bg: 'bg-emerald-50', 
          icon: 'text-emerald-500', 
          hover: 'hover:bg-emerald-100',
          button: 'bg-emerald-500 hover:bg-emerald-600'
        };
      case 'Geometry':
        return { 
          bg: 'bg-cyan-50', 
          icon: 'text-cyan-500', 
          hover: 'hover:bg-cyan-100',
          button: 'bg-cyan-500 hover:bg-cyan-600'
        };
      case 'Mensuration':
        return { 
          bg: 'bg-amber-50', 
          icon: 'text-amber-500', 
          hover: 'hover:bg-amber-100',
          button: 'bg-amber-500 hover:bg-amber-600'
        };
      case 'Trigonometry':
        return { 
          bg: 'bg-rose-50', 
          icon: 'text-rose-500', 
          hover: 'hover:bg-rose-100',
          button: 'bg-rose-500 hover:bg-rose-600'
        };
      case 'Data Interpretation':
        return { 
          bg: 'bg-violet-50', 
          icon: 'text-violet-500', 
          hover: 'hover:bg-violet-100',
          button: 'bg-violet-500 hover:bg-violet-600'
        };
      default:
        return { 
          bg: 'bg-teal-50', 
          icon: 'text-teal-500', 
          hover: 'hover:bg-teal-100',
          button: 'bg-teal-500 hover:bg-teal-600'
        };
    }
  };

  const getTopicIcon = (name, category) => {
    const { icon } = getCategoryColor(category);
    const iconClass = `w-8 h-8 ${icon}`;
    switch (name.toLowerCase()) {
      // Arithmetic
      case 'number system': return <Hash className={iconClass} />;
      case 'simplification': return <Equal className={iconClass} />;
      case 'hcf and lcm': return <Infinity className={iconClass} />;
      case 'average': return <TrendingUp className={iconClass} />;
      case 'percentage': return <Percent className={iconClass} />;
      case 'profit and loss': return <DollarSign className={iconClass} />;
      case 'discount': return <DollarSign className={iconClass} />;
      case 'simple interest': return <TrendingUp className={iconClass} />;
      case 'compound interest': return <TrendingUp className={iconClass} />;
      case 'ratio and proportion': return <Equal className={iconClass} />;
      case 'partnership': return <Users className={iconClass} />;
      case 'mixture and alligation': return <Droplets className={iconClass} />;
      case 'time and work': return <Clock className={iconClass} />;
      case 'pipe and cistern': return <Droplets className={iconClass} />;
      case 'speed, time, and distance': return <Car className={iconClass} />;
      case 'boat and stream': return <Boat className={iconClass} />;
      
      // Algebra
      case 'basics of algebra': return <Function className={iconClass} />;
      case 'linear equations': return <Function className={iconClass} />;
      case 'quadratic equations': return <Function className={iconClass} />;
      
      // Geometry
      case 'basics of geometry (lines, angles)': return <Ruler className={iconClass} />;
      case 'triangles (properties, similarity, congruence)': return <Triangle className={iconClass} />;
      case 'circles (properties and theorems)': return <Circle className={iconClass} />;
      case 'polygons': return <Hexagon className={iconClass} />;
      case 'coordinate geometry': return <Target className={iconClass} />;
      
      // Data Interpretation
      case 'tabulation': return <Table className={iconClass} />;
      case 'bar graph': return <BarChart2 className={iconClass} />;
      case 'pie chart': return <PieChart2 className={iconClass} />;
      case 'line graph': return <LineChart className={iconClass} />;
      
      // Miscellaneous
      case 'sets and venn diagrams': return <Venn className={iconClass} />;
      case 'sequence and series': return <ListOrdered className={iconClass} />;
      case 'probability': return <Dice className={iconClass} />;
      case 'permutations and combinations': return <Sparkles className={iconClass} />;
      case 'problems on ages': return <Users className={iconClass} />;
      
      default: return <Lightbulb className={iconClass} />;
    }
  };

  const getCategoryIcon = (name) => {
    const { icon } = getCategoryColor(name);
    const iconClass = `w-10 h-10 ${icon}`;
    switch (name) {
      case 'Arithmetic': return <Calculator className={iconClass} />;
      case 'Algebra': return <Book className={iconClass} />;
      case 'Geometry': return <Shapes className={iconClass} />;
      case 'Mensuration': return <Ruler className={iconClass} />;
      case 'Trigonometry': return <Triangle className={iconClass} />;
      case 'Data Interpretation': return <PieChart className={iconClass} />;
      default: return <Brain className={iconClass} />;
    }
  };

  // Calculate total number of topics for SEO description
  const totalTopics = categories.reduce((sum, category) => sum + category.topics.length, 0);

  return (
    <>
      <Helmet>
        <title>Aptitude Mock Tests | Practice Arithmetic, Algebra, Geometry & More</title>
        <meta name="description" content={`Prepare for competitive exams with our ${totalTopics}+ aptitude mock tests covering Arithmetic, Algebra, Geometry, Data Interpretation, and more. Practice with our tests and detailed explanations.`} />
        <meta name="keywords" content="aptitude tests, aptitude questions, aptitude mock tests, free aptitude tests, arithmetic practice, algebra problems, geometry questions, data interpretation, competitive exam preparation" />
        <link rel="canonical" href="https://mocktestpro.com/aptitude" />
        <meta property="og:title" content="Aptitude Mock Tests | Practice Arithmetic, Algebra, Geometry & More" />
        <meta property="og:description" content={`Prepare for competitive exams with our ${totalTopics}+ aptitude mock tests covering Arithmetic, Algebra, Geometry, Data Interpretation, and more.`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://mocktestpro.com/aptitude" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Aptitude Mock Tests | Practice Arithmetic, Algebra, Geometry & More" />
        <meta name="twitter:description" content={`Prepare for competitive exams with our ${totalTopics}+ aptitude mock tests covering Arithmetic, Algebra, Geometry, Data Interpretation, and more.`} />
      </Helmet>
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50">

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Search and Filter Section */}
        <div className="mb-8 space-y-4">
          <div className="relative max-w-2xl mx-auto">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-6 h-6" />
            <input
              type="text"
              placeholder="Search topics..."
              className="w-full pl-12 pr-4 py-4 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-400 focus:border-transparent bg-white shadow-sm transition-all duration-200"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          
          {/* Category Tabs */}
          <div className="flex flex-wrap gap-3 justify-center">
            <button
              onClick={() => setSelectedCategory(null)}
              className={`px-6 py-3 rounded-xl text-sm font-medium transition-all duration-200
                ${!selectedCategory 
                  ? 'bg-blue-500 text-white shadow-sm' 
                  : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'}`}
            >
              All Topics
            </button>
            {categories.map((category) => {
              const { bg, hover } = getCategoryColor(category.name);
              return (
                <button
                  key={category.name}
                  onClick={() => setSelectedCategory(category.name)}
                  className={`px-6 py-3 rounded-xl text-sm font-medium transition-all duration-200
                    ${selectedCategory === category.name 
                      ? `${bg} shadow-sm border border-gray-200` 
                      : `bg-white text-gray-700 ${hover} border border-gray-200`}`}
                >
                  {category.name}
                </button>
              );
            })}
          </div>
        </div>

        {/* Categories Grid */}
        <div className="space-y-12">
          {filteredCategories.map((category) => {
            const { bg, icon, button } = getCategoryColor(category.name);
            return (
              <div key={category.name} className="space-y-6">
                <div className={`flex items-center space-x-4 ${bg} p-6 rounded-xl shadow-sm border border-gray-100`}>
                  {getCategoryIcon(category.name)}
                  <h2 className={`text-2xl font-bold ${icon}`}>{category.name}</h2>
                </div>
                
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {category.topics.map((topic) => {
                    const { bg, hover } = getCategoryColor(category.name);
                    
                    return (
                      <div
                        key={topic.name}
                        className="group bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100"
                      >
                        <div className="p-6">
                          <div className="flex items-start justify-between mb-4">
                            <div className={`p-3 ${bg} rounded-xl ${hover} transition-colors duration-200`}>
                              {getTopicIcon(topic.name, category.name)}
                            </div>
                            {/* <div className="flex items-center space-x-2 text-sm text-gray-500">
                              <Timer className="w-5 h-5" />
                              <span>30 mins</span>
                            </div> */}
                          </div>
                          <h3 className="text-lg font-semibold text-gray-800 mb-3">{topic.name}</h3>
                          <div className="flex items-center text-sm text-gray-500 mb-4">
                            <Award className="w-5 h-5 mr-2" />
                            <span>8 Mock Tests Available</span>
                          </div>
                          <a
                            href={topic.url}
                            className={`w-full ${button} text-white py-3 px-4 rounded-lg shadow-sm hover:shadow transition-all duration-200 flex items-center justify-center group no-underline`}
                          >
                            <span>View Mock Tests</span>
                            <ChevronRight className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform duration-200" />
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </main>
      <Footer />
    </div>
          {/* Structured data for SEO */}
          <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Aptitude Mock Tests | Practice Arithmetic, Algebra, Geometry & More",
          "description": `Prepare for competitive exams with our ${totalTopics}+ aptitude mock tests covering Arithmetic, Algebra, Geometry, Data Interpretation, and more.`,
          "publisher": {
            "@type": "Organization",
            "name": "MockTestPro",
            "logo": {
              "@type": "ImageObject",
              "url": "https://mocktestpro.com/logo.png"
            }
          },
          "mainEntity": {
            "@type": "ItemList",
            "itemListElement": categories.map((category, index) => ({
              "@type": "ListItem",
              "position": index + 1,
              "name": category.name,
              "item": {
                "@type": "Course",
                "name": `${category.name} Aptitude Tests`,
                "description": `Practice ${category.name} aptitude questions with timed mock tests`,
                "provider": {
                  "@type": "Organization",
                  "name": "MockTestPro"
                }
              }
            }))
          }
        })}
      </script>
    </>
  );
}