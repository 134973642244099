import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Clock, BookOpen, Download, CheckCircle, ChevronRight, Tag, FolderOpen } from 'lucide-react';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { getArticleBySlug } from '../../services/api';
import Footer from '../../components/Footer';

export default function ArticlePage() {
  const { examId, categorySlug, articleSlug } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticle = async () => {
      setLoading(true);
      try {
        const response = await getArticleBySlug(examId, categorySlug, articleSlug);
        setArticle(response.data);
      } finally {
        setLoading(false);
      }
    };
    fetchArticle();
  }, [categorySlug, articleSlug]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-pulse">
          <div className="h-8 w-64 bg-gray-200 rounded mb-4"></div>
          <div className="h-4 w-48 bg-gray-200 rounded"></div>
        </div>
      </div>
    );
  }

  if (!article) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900">Article not found</h2>
          <Link to={`/exam/category/${categorySlug}`} className="text-indigo-600 hover:text-indigo-700 mt-4 inline-block">
            Go back to category
          </Link>
        </div>
      </div>
    );
  }

  const renderBlock = (block) => {
    switch (block.__component) {
      case 'shared.rich-text':
        return (
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              h1: ({node, ...props}) => <h1 className="text-3xl font-extrabold mt-8 mb-4" {...props} />,
              h2: ({node, ...props}) => <h2 className="text-2xl font-bold mt-8 mb-4" {...props} />,
              h3: ({node, ...props}) => <h3 className="text-xl font-semibold mt-6 mb-3" {...props} />,
              p: ({node, ...props}) => <p className="mb-4 text-gray-700" {...props} />,
              a: ({node, ...props}) => (
                <a className="text-indigo-600 hover:text-indigo-800 underline" target="_blank" rel="noopener noreferrer" {...props} />
              ),
              blockquote: ({node, ...props}) => (
                <blockquote className="border-l-4 border-gray-300 pl-4 italic text-gray-600 my-4" {...props} />
              ),
              code: ({node, inline, ...props}) => 
                inline ? (
                  <code className="bg-gray-100 rounded px-2 py-1 text-sm" {...props} />
                ) : (
                  <pre className="bg-gray-800 text-gray-100 rounded p-4 overflow-auto my-4">
                    <code {...props} />
                  </pre>
                ),
              img: ({node, ...props}) => (
                <img className="mx-auto my-4 max-w-full h-auto" {...props} />
              ),
              table: ({node, ...props}) => (
                <div className="overflow-x-auto my-4">
                  <table className="min-w-full bg-white border border-gray-200" {...props} />
                </div>
              ),
              th: ({node, ...props}) => (
                <th className="px-6 py-3 bg-gray-100 text-left text-xs font-bold text-gray-600 uppercase tracking-wider border border-gray-200" {...props} />
              ),
              td: ({node, ...props}) => (
                <td className="px-6 py-4 text-sm text-gray-700 border border-gray-200" {...props} />
              ),
              tr: ({node, ...props}) => <tr className="bg-white hover:bg-gray-50" {...props} />
            }}
          >
            {block.body}
          </ReactMarkdown>
        );
      case 'shared.media':
        return block.file && (
          <img 
            src={block.file.url} 
            alt={block.file.alternativeText || ''} 
            className="w-full rounded-lg shadow-md my-6"
            loading="lazy"
          />
        );
      case 'shared.quote':
        return (
          <blockquote className="border-l-4 border-indigo-500 pl-4 my-6">
            <h3 className="text-xl font-semibold mb-2">{block.title}</h3>
            <p className="text-gray-600">{block.body}</p>
          </blockquote>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>{article.seo.metaTitle}</title>
        <meta name="description" content={article.seo.metaDescription} />
        <meta name="keywords" content={article.seo.keywords} />
        <meta name="robots" content="index, follow" />
        {article.seo.canonicalURL && <link rel="canonical" href={article.seo.canonicalURL} />}
        {/* {article.seo.metaRobots && <meta name="robots" content={article.seo.metaRobots} />} */}
        {article.seo.metaViewport && <meta name="viewport" content={article.seo.metaViewport} />}
        {article.seo.structuredData && (
          <script type="application/ld+json">
            {JSON.stringify(article.seo.structuredData)}
          </script>
        )}
      </Helmet>

      <div className="bg-gray-50 min-h-screen py-6 sm:py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            <div className="lg:col-span-8">
              <article className="bg-white rounded-xl shadow-md p-4 sm:p-8">
                <div className="mb-8">
                  <div className="text-center mb-8">
                    <div className="flex items-center justify-center space-x-2 text-sm text-gray-500 uppercase tracking-wider mb-4">
                      <Link to={`/articles/${examId}`} className="hover:text-indigo-600">
                        {examId?.toUpperCase()}
                      </Link>
                      <ChevronRight className="h-4 w-4" />
                      <Link to={`/articles/${examId}/${categorySlug}`} className="hover:text-indigo-600">
                      {categorySlug?.toUpperCase()}
                      </Link>
                      {/* <ChevronRight className="h-4 w-4" />
                      <span>{article.title}</span> */}
                    </div>
                    <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">
                      {article.title}
                    </h1>
                    <div className="flex items-center justify-center space-x-4 text-sm text-gray-500 mb-4">
                      <div className="flex items-center">
                        <Clock className="h-4 w-4 mr-1" />
                        {article.readingTime} min read
                      </div>
                      <span>•</span>
                      <div>{new Date(article.publishedAt).toLocaleDateString()}</div>
                    </div>
                    <p className="text-lg text-gray-600 max-w-2xl mx-auto mb-4">
                      {article.excerpt}
                    </p>
                    {article.seo.keywords && (
                      <div className="flex flex-wrap items-center justify-center gap-2">
                        {(Array.isArray(article.seo.keywords) 
                          ? article.seo.keywords 
                          : article.seo.keywords.split(',').map(k => k.trim())
                        ).map((keyword, index) => (
                          <span key={index} className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-gray-100 text-gray-700">
                            <Tag className="h-3 w-3 mr-1" />
                            {keyword}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="prose max-w-none">
                    {article.blocks && article.blocks.map((block, index) => (
                      <div key={index}>
                        {renderBlock(block)}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="border-t border-gray-200 pt-8 mt-8">
                  <div className="flex flex-wrap gap-4">
                    {article.practiceTestUrl && (
                      <Link
                        to={article.practiceTestUrl}
                        className="flex items-center space-x-2 bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors"
                      >
                        <BookOpen className="h-5 w-5" />
                        <span>Start Practice Test</span>
                      </Link>
                    )}
                    {article.pdfUrl && (
                      <a 
                        href={article.pdfUrl}
                        className="flex items-center space-x-2 bg-gray-100 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors"
                      >
                        <Download className="h-5 w-5" />
                        <span>Download PDF</span>
                      </a>
                    )}
                  </div>
                </div>
              </article>
            </div>

            <aside className="lg:col-span-4 space-y-6">
              {/* Related Articles */}
              <div className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow duration-200">
                <h2 className="text-xl font-semibold text-gray-900 mb-6 flex items-center">
                  <BookOpen className="h-5 w-5 mr-2 text-indigo-600" />
                  Related Articles
                </h2>
                <div className="space-y-4">
                  {article.relatedArticles.map((related) => (
                    <Link
                      key={related.slug}
                      to={`/articles/${examId}/${categorySlug}/${related.slug}`}
                      className="block group p-4 rounded-lg hover:bg-gray-50 transition-colors duration-200"
                    >
                      <h3 className="text-gray-900 group-hover:text-indigo-600 font-medium mb-2 line-clamp-2">
                        {related.title}
                      </h3>
                      <div className="flex items-center text-sm text-gray-500">
                        <Clock className="h-4 w-4 mr-2" />
                        <span>{related.readingTime} min read</span>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>

              {/* Related Categories */}
              <div className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow duration-200">
                <h2 className="text-xl font-semibold text-gray-900 mb-6 flex items-center">
                  <FolderOpen className="h-5 w-5 mr-2 text-indigo-600" />
                  Related Categories
                </h2>
                <div className="space-y-4">
                  {article.relatedCategories.map((category) => (
                    <Link
                      key={category.slug}
                      to={`/articles/${examId}/${category.slug}/`}
                      className="block group p-4 rounded-lg hover:bg-gray-50 transition-colors duration-200"
                    >
                      <h3 className="text-gray-900 group-hover:text-indigo-600 font-medium mb-2">
                        {category.name}
                      </h3>
                      <p className="text-sm text-gray-500 line-clamp-2">
                        {category.description}
                      </p>
                    </Link>
                  ))}
                </div>
              </div>

              {/* Study Resources section remains the same */}
              <div className="bg-white rounded-xl shadow-md p-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">
                  Study Resources
                </h3>
                <div className="space-y-4">
                  {article.studyResources?.map((resource, index) => (
                    <div key={index} className="flex items-center space-x-3">
                      <div className={`bg-${resource.color}-100 p-2 rounded-lg`}>
                        <BookOpen className={`h-5 w-5 text-${resource.color}-600`} />
                      </div>
                      <div>
                        <h4 className="font-medium">{resource.title}</h4>
                        <p className="text-sm text-gray-600">{resource.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </aside>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
