import React from 'react';
import styled from 'styled-components';
import {  FaLink } from 'react-icons/fa';

const ResourcesContainer = styled.section`
  margin: 20px 0;
`;

const ResourceList = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
`;

const ResourceItem = styled.li`
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 15px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const ResourceLink = styled.a`
  color: #0066cc;
  text-decoration: none;
  font-weight: bold;
  display: block;

  &:hover {
    text-decoration: underline;
  }
`;

const ResourceDescription = styled.p`
  margin-top: 5px;
  font-size: 0.9em;
  color: #555;
`;

const ResourcesSection = ({ resources }) => (
    <ResourcesContainer>
      <h2><FaLink aria-hidden="true" style={{ marginRight: '0.5em' }} /> Additional Resources</h2>
      <ResourceList>
        {resources.map((resource, index) => (
          <ResourceItem key={index}>
            <ResourceLink href={resource.resourceLink} target="_blank" rel="noopener noreferrer">
              {resource.resourceName}
            </ResourceLink>
            {resource.description && (
              <ResourceDescription>{resource.description}</ResourceDescription>
            )}
          </ResourceItem>
        ))}
      </ResourceList>
    </ResourcesContainer>
  );

export default ResourcesSection;